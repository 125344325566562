import { Hole } from '@utils/hooks/holes';
import { Match, MatchTeam } from '../hooks/matches';
import { getWinnerBackgroundColor, calculateMaxScore } from '@utils/matchUtils';
import { matchTypeKeys } from '@utils/constants/matchConstants';

type Props = {
  holes: Hole[];
  match: Match;
};

const individualBestBallTeamScorecard = (match: Match, matchTeam: MatchTeam, startHole: number, endHole: number, holes: Hole[]) => {
  return matchTeam.matchPlayers.map((player, i) => {
    return (
      <tr key={`${match.id}_t1_p${i}_scores`}>
        <td className="small">{player.nameShort}</td>
        {matchTeam.matchScores[i].slice(startHole - 1, endHole).map((score, hi) => (
          <td key={`${match.id}_t1_p${i}_scores_h${hi}`} className="text-center small" style={{ width: '9%' }}>
            {score.grossScore ? (score.grossScore != 99 ? score.grossScore : 'X') : null}
          </td>
        ))}
        <td className="small">
          {matchTeam.matchScores[i]
            .slice(startHole - 1, endHole)
            .map((x, hi) => {
              return { holeNumber: hi + startHole, grossScore: x.grossScore };
            })
            .reduce((partialSum, a) => partialSum + calculateMaxScore(a.holeNumber, matchTeam.playerHandicaps[i], a.grossScore, holes), 0)}
        </td>
      </tr>
    );
  });
};

const foursomesTeamScorecard = (match: Match, matchTeam: MatchTeam, teamKey: string, startHole: number, endHole: number, holes: Hole[]) => {
  return (
    <tr key={`${match.id}_${teamKey}_scores`}>
      <td className="small">
        {matchTeam.matchPlayers[0].lastName}/{matchTeam.matchPlayers[1].lastName}
      </td>
      {matchTeam.matchScores[0].slice(startHole - 1, endHole).map((score, hi) => (
        <td key={`${match.id}_${teamKey}_scores_h${hi}`} className="text-center small" style={{ width: '9%' }}>
          {score.grossScore ? (score.grossScore != 99 ? score.grossScore : 'X') : null}
        </td>
      ))}
      <td className="small">
        {matchTeam.matchScores[0]
          .slice(startHole - 1, endHole)
          .map((x, hi) => {
            return { holeNumber: hi + startHole, grossScore: x.grossScore };
          })
          .reduce((partialSum, a) => partialSum + calculateMaxScore(a.holeNumber, matchTeam.playerHandicaps[0], a.grossScore, holes), 0)}
      </td>
    </tr>
  );
};

const netMatchScoreCell = (match: Match, holeNumber: number) => {
  let t1NetScore: number = null;
  let t2NetScore: number = null;

  if (match.matchTypeKey == matchTypeKeys.Fourballs) {
    // Need to find the lowest of two scores
    t1NetScore = Math.min(
      match.team1.matchScores[0][holeNumber - 1].netScoreForHoleCalculation,
      match.team1.matchScores[1][holeNumber - 1].netScoreForHoleCalculation
    );
    t2NetScore = Math.min(
      match.team2.matchScores[0][holeNumber - 1].netScoreForHoleCalculation,
      match.team2.matchScores[1][holeNumber - 1].netScoreForHoleCalculation
    );
  } else {
    // Only one score per team so just get the value
    t1NetScore = match.team1.matchScores[0][holeNumber - 1].netScoreForHoleCalculation;
    t2NetScore = match.team2.matchScores[0][holeNumber - 1].netScoreForHoleCalculation;
  }

  let winningNetScore: number = null;
  let cellBackgroundClass = '';

  if (t1NetScore == t2NetScore) {
    winningNetScore = t1NetScore;
  } else if (t1NetScore < t2NetScore) {
    winningNetScore = t1NetScore;
    cellBackgroundClass = 'table-danger';
  } else if (t2NetScore < t1NetScore) {
    winningNetScore = t2NetScore;
    cellBackgroundClass = 'table-success';
  }

  return (
    <td key={`${match.id}_netscores_h${holeNumber}`} className={`text-center small ${cellBackgroundClass}`} style={{ width: '9%' }}>
      {winningNetScore}
    </td>
  );
};

const sideScorecard = (holes: Hole[], match: Match, startHole: number, endHole: number) => {
  const sideHoles = holes.slice(startHole - 1, endHole);
  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th className="small">Hole</th>
          {sideHoles.map((x) => (
            <th className="text-center small" style={{ width: '9%' }} key={`h${x.holeNumber}_number`}>
              {x.holeNumber}
            </th>
          ))}
          <th className="text-center">T</th>
        </tr>
        <tr>
          <th className="small">Par</th>
          {sideHoles.map((x) => (
            <th className="text-center small" key={`h${x.holeNumber}_par`}>
              {x.holePar}
            </th>
          ))}
          <th>{sideHoles.reduce((partialSum, a) => partialSum + a.holePar, 0)}</th>
        </tr>
        <tr>
          <th className="small">Handicap</th>
          {sideHoles.map((x) => (
            <th className="text-center small" key={`h${x.holeNumber}_handicap`}>
              {x.holeHandicap}
            </th>
          ))}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {match.matchTypeKey == matchTypeKeys.Fourballs && (
          <>
            {individualBestBallTeamScorecard(match, match.team1, startHole, endHole, holes)}
            <tr>
              <td></td>
              {sideHoles.map((x, hi) => netMatchScoreCell(match, hi + startHole))}
              <td></td>
            </tr>
            {individualBestBallTeamScorecard(match, match.team2, startHole, endHole, holes)}
          </>
        )}
        {match.matchTypeKey == matchTypeKeys.Foursomes && (
          <>
            {foursomesTeamScorecard(match, match.team1, 'Team1', startHole, endHole, holes)}
            <tr>
              <td></td>
              {sideHoles.map((x, hi) => netMatchScoreCell(match, hi + startHole))}
            </tr>
            {foursomesTeamScorecard(match, match.team2, 'Team2', startHole, endHole, holes)}
          </>
        )}
        {match.matchTypeKey == matchTypeKeys.Singles && (
          <>
            {individualBestBallTeamScorecard(match, match.team1, startHole, endHole, holes)}
            <tr>
              <td></td>
              {sideHoles.map((x, hi) => netMatchScoreCell(match, hi + startHole))}
            </tr>
            {individualBestBallTeamScorecard(match, match.team2, startHole, endHole, holes)}
          </>
        )}
      </tbody>
    </table>
  );
};

export default ({ holes, match }: Props) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h5>Front</h5>
        <h6>
          <span className={`badge ms-2 ${getWinnerBackgroundColor(match.team1.frontPointsWon, match.team2.frontPointsWon)}`}>
            {match.frontResult}
          </span>
        </h6>
      </div>
      {sideScorecard(holes, match, 1, 9)}
      <div className="d-flex justify-content-between align-items-center">
        <h5>Back</h5>
        <h6>
          <span className={`badge ms-2 ${getWinnerBackgroundColor(match.team1.backPointsWon, match.team2.backPointsWon)}`}>{match.backResult}</span>
        </h6>
      </div>
      {sideScorecard(holes, match, 10, 18)}
      <div className="d-flex justify-content-between align-items-center">
        <h5>18 Hole Match</h5>
        <h6>
          <span className={`badge ms-2 ${getWinnerBackgroundColor(match.team1.all18PointsWon, match.team2.all18PointsWon)}`}>
            {match.all18Result}
          </span>
        </h6>
      </div>
    </>
  );
};
