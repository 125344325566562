import { matchTypeKeys } from '@utils/constants/matchConstants';
import { Hole } from '@utils/hooks/holes';
import { getWinnerBackgroundColor } from '@utils/matchUtils';
import { useState } from 'react';
import { Match, MatchTeam } from '../hooks/matches';
import MatchScorecard from './matchScorecard';

type Props = {
  match: Match;
  holes: Hole[];
};

const renderDecimalScore = (score: number) => {
  if (Math.floor(score) == score) {
    return <>{score}</>;
  } else {
    const wholeNumber = Math.floor(score);
    const decimalPart = score - wholeNumber;

    if (decimalPart == 0.5) {
      if (wholeNumber == 0) {
        return (
          <span>
            <sup>1</sup>&frasl;<sub>2</sub>
          </span>
        );
      } else {
        return (
          <span>
            {wholeNumber} <sup>1</sup>&frasl;<sub>2</sub>
          </span>
        );
      }
    }
  }
};

const renderMatchTeam = (match: Match, team: MatchTeam, teamKey: string) => {
  let nameOrderClass = 'order-1';
  let nameTextAlignClass = 'text-start';
  let handicapOrderClass = 'order-2';
  let handicapMarginClass = 'ms-2';

  if (teamKey == 'Team2') {
    nameOrderClass = 'order-2';
    nameTextAlignClass = 'text-end';
    handicapOrderClass = 'order-1';
    handicapMarginClass = 'me-2';
  }

  if (match.matchTypeKey == matchTypeKeys.Foursomes) {
    return (
      <div className="d-flex align-items-center">
        <div className={`${nameOrderClass} ${nameTextAlignClass}`}>
          {team.matchPlayers.map((x, index) => (
            <p key={`match_${match.id}_${teamKey}_${index}`} className="mb-1">
              <span className="h5">{x.nameFirstLast}</span>
            </p>
          ))}
        </div>
        <div className={`align-middle ${handicapMarginClass} ${handicapOrderClass}`}>
          <h5>({team.playerHandicaps[0]})</h5>
        </div>
      </div>
    );
  }

  return (
    <div>
      {team.matchPlayers.map((x, index) => (
        <p key={`match_${match.id}_${teamKey}_${index}`} className={`mb-1 ${nameTextAlignClass}`}>
          <span className="h5">
            {x.nameFirstLast}
            <small className={`ms-2`}>({team.playerHandicaps[index]})</small>
          </span>
        </p>
      ))}
    </div>
  );
};

export default ({ match, holes }: Props) => {
  const [showScores, setShowScores] = useState(false);

  return (
    <div className="row g-0 mb-3">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <h4>
              Match #{match.matchNumber}{' '}
              <span
                className={`badge float-end ${getWinnerBackgroundColor(
                  match.team1.all18PointsWon + match.team1.frontPointsWon + match.team1.backPointsWon,
                  match.team2.all18PointsWon + match.team2.frontPointsWon + match.team2.backPointsWon
                )}`}
              >
                {renderDecimalScore(match.team1.all18PointsWon + match.team1.frontPointsWon + match.team1.backPointsWon)}&nbsp;-&nbsp;
                {renderDecimalScore(match.team2.all18PointsWon + match.team2.frontPointsWon + match.team2.backPointsWon)}
              </span>
            </h4>
          </div>

          <div className="card-body">
            <div className="row mb-2">
              <div className="d-flex justify-content-between">
                {renderMatchTeam(match, match.team1, 'Team1')}
                {renderMatchTeam(match, match.team2, 'Team2')}
              </div>
            </div>
            {match.isConverted == false && (
              <div className="row mb-1">
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-link"
                    onClick={(e) => {
                      setShowScores(!showScores);
                      (e.target as HTMLButtonElement).blur();
                    }}
                  >
                    {showScores ? 'Hide Scores' : 'View Scores'}
                  </button>
                </div>
              </div>
            )}
            {showScores && (
              <div className="row mb-1">
                <MatchScorecard holes={holes} match={match} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
