import { getJson } from '@utils/apiClient';
import { useEffect, useState } from 'react';

import { getTeamsRoute } from '@routeBuilders/teamsRouteBuilder';
import { Year } from './years';

export interface Team {
  teamKey: string;
  teamName: string;
  isWinner: boolean;
  totalPoints: number;
  teamPlayers: TeamPlayer[];
}

export interface TeamPlayer {
  playerName: {
    firstName: string;
    lastName: string;
    nameShort: string;
    nameFirstLast: string;
    nameLastFirst: string;
  };
  teamRole?: string;
  handicap: number;
}

const useTeams = (selectedYear: Year): { team1: Team; team2: Team } => {
  const [team1, setTeam1] = useState<Team>(null);
  const [team2, setTeam2] = useState<Team>(null);

  const loadData = async (): Promise<void> => {
    if (selectedYear) {
      const data = await getJson<{ team1: Team; team2: Team }>(getTeamsRoute(selectedYear.year));

      setTeam1(data.team1);
      setTeam2(data.team2);
    }
  };

  useEffect(() => {
    loadData();
  }, [selectedYear]);

  return { team1, team2 };
};

export default useTeams;
