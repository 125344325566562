import ContentLoader from 'react-content-loader';

const loadingIndicator = (props) => {
  return (
    <div className="d-flex justify-content-center mt-5">
      <i className="fa-solid fa-spinner fa-spin-pulse fa-5x"></i>
    </div>
  );
};

export default loadingIndicator;
