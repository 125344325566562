import { getMatchesRoute } from '@routeBuilders/matchesRouteBuilder';
import { getJson } from '@utils/apiClient';
import { useEffect, useState } from 'react';
import { Year } from '../../../utils/hooks/years';

// export interface MatchPlayer {
//   playerName: {
//     firstName: string;
//     lastName: string;
//     nameShort: string;
//     nameFirstLast: string;
//     nameLastFirst: string;
//   };
//   handicap: number;
// }

export interface MatchTeam {
  matchPlayers: {
    firstName: string;
    lastName: string;
    nameShort: string;
    nameFirstLast: string;
    nameLastFirst: string;
  }[];
  playerHandicaps: number[];
  matchScores: { grossScore?: number; netScoreForHoleCalculation?: number; netScoreForTotalCalculation?: number }[][];
  frontPointsWon: number;
  backPointsWon: number;
  all18PointsWon: number;
}

export interface Match {
  id: string;
  matchTypeKey: string;
  matchType: string;
  matchNumber: number;
  isInProgress: Boolean;
  isCompleted: boolean;
  frontResult: string;
  backResult: string;
  all18Result: string;
  team1: MatchTeam;
  team2: MatchTeam;
  isConverted: boolean;
}

export interface SkinHoleResult {
  holeNumber: number;
  hasSkin: boolean;
  holeScores: {
    playerName: string;
    score: number;
  }[];
}

const useMatches = (selectedYear: Year) => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [fourballGrossSkins, setFourballGrossSkins] = useState<SkinHoleResult[]>([]);
  const [fourballNetSkins, setFourballNetSkins] = useState<SkinHoleResult[]>([]);
  const [foursomesGrossSkins, setFoursomesGrossSkins] = useState<SkinHoleResult[]>([]);
  const [foursomesNetSkins, setFoursomesNetSkins] = useState<SkinHoleResult[]>([]);
  const [singlesGrossSkins, setSinglesGrossSkins] = useState<SkinHoleResult[]>([]);
  const [singlesNetSkins, setSinglesNetSkins] = useState<SkinHoleResult[]>([]);

  const loadData = async () => {
    if (selectedYear) {
      const data = await getJson<{
        matches: Match[];
        skins: {
          fourballs: { grossSkins: SkinHoleResult[]; netSkins: SkinHoleResult[] };
          foursomes: { grossSkins: SkinHoleResult[]; netSkins: SkinHoleResult[] };
          singles: { grossSkins: SkinHoleResult[]; netSkins: SkinHoleResult[] };
        };
      }>(getMatchesRoute(selectedYear.year));

      setMatches(data.matches);

      setFourballGrossSkins(data.skins.fourballs.grossSkins);
      setFourballNetSkins(data.skins.fourballs.netSkins);
      setFoursomesGrossSkins(data.skins.foursomes.grossSkins);
      setFoursomesNetSkins(data.skins.foursomes.netSkins);
      setSinglesGrossSkins(data.skins.singles.grossSkins);
      setSinglesNetSkins(data.skins.singles.netSkins);
    }
  };

  useEffect(() => {
    loadData();
  }, [selectedYear]);

  return { matches, fourballGrossSkins, fourballNetSkins, foursomesGrossSkins, foursomesNetSkins, singlesGrossSkins, singlesNetSkins };
};

export default useMatches;
