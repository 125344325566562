import useMatches, { Match, MatchTeam, SkinHoleResult } from '../hooks/matches';

import useYears from '@utils/hooks/years';
import useHoles from '@utils/hooks/holes';

import Layout from '@utils/components/layout';
import MatchInstance from './match';
import { matchTypeKeys } from '@utils/constants/matchConstants';

import LoadingIndicator from '@utils/components/loadingIndicator';
import { useEffect, useState } from 'react';
import useTeams from '@utils/hooks/teams';

const renderSkinsResultTable = (skins: SkinHoleResult[]) => {
  return (
    <table className="table table-striped table-sm">
      <thead>
        <tr>
          <th>Hole</th>
          <th>Skin</th>
        </tr>
      </thead>
      <tbody>
        {skins.map((x) => (
          <tr key={`fb_gs_h${x.holeNumber}`}>
            <td>{x.holeNumber}</td>
            <td>{getSkinHolderPlayerName(x)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const renderNetScoreTable = (matches: Match[]) => {
  const getNetScoresForTeam = (match: Match, team: MatchTeam) => {
    let netScoresForTotal: number[] = team.matchScores[0].map((s) => s.netScoreForTotalCalculation); // Start with the first match score for foursomes and singles

    if (match.matchTypeKey == matchTypeKeys.Fourballs) {
      // Get the min of two scores
      netScoresForTotal = Array.from({ length: 18 }, (v, hi) => {
        return Math.min(team.matchScores[0][hi].netScoreForTotalCalculation, team.matchScores[1][hi].netScoreForTotalCalculation);
      });
    }

    return {
      players: team.matchPlayers.map((p) => p.lastName).join('/'),
      netTotal: netScoresForTotal.reduce((partialSum, a) => partialSum + a, 0),
    };
  };

  const team1NetResults: { players: string; netTotal: number }[] = matches.map((x) => {
    return getNetScoresForTeam(x, x.team1);
  });
  const team2NetResults: { players: string; netTotal: number }[] = matches.map((x) => {
    return getNetScoresForTeam(x, x.team2);
  });

  const netResults: { players: string; netTotal: number }[] = [...team1NetResults, ...team2NetResults];

  netResults.forEach((x) => {
    if (x.netTotal == 0) {
      x.netTotal = 99;
    }
  });

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Players</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        {netResults
          .sort((a, b) => a.netTotal - b.netTotal)
          .map((x, teamIndex) => (
            <tr key={`netleaders_${teamIndex}`}>
              <td>{x.players}</td>
              <td>{x.netTotal == 99 ? '' : x.netTotal}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

const getSkinHolderPlayerName = (holeResult: SkinHoleResult) => {
  if (holeResult.hasSkin) {
    const lowHoleScore = holeResult.holeScores.reduce((prev, curr) => (prev.score < curr.score ? prev : curr));

    return `${lowHoleScore.playerName} - ${lowHoleScore.score}`;
  }

  return null;
};

export default () => {
  const { years, selectedYear } = useYears();

  const { holes } = useHoles();

  const { team1, team2 } = useTeams(selectedYear);

  const { matches, fourballGrossSkins, fourballNetSkins, foursomesGrossSkins, foursomesNetSkins, singlesGrossSkins, singlesNetSkins } =
    useMatches(selectedYear);

  const [selectedMatchTypeKey, setSelectedMatchTypeKey] = useState('TeamRosters');

  useEffect(() => {
    if (selectedYear && matches && matches.length > 0) {
      if (matches.filter((x) => x.matchTypeKey == matchTypeKeys.Singles).some((x) => x.isInProgress)) {
        setSelectedMatchTypeKey(matchTypeKeys.Singles);
      } else if (matches.filter((x) => x.matchTypeKey == matchTypeKeys.Foursomes).some((x) => x.isInProgress)) {
        setSelectedMatchTypeKey(matchTypeKeys.Foursomes);
      } else {
        setSelectedMatchTypeKey(matchTypeKeys.Fourballs);
      }
    }
  }, [selectedYear, matches]);

  if (!selectedYear || !team1 || !team2) {
    return <LoadingIndicator></LoadingIndicator>;
  }

  if (selectedYear) {
    const header = (
      <header className="bg-primary py-2">
        <div className="container px-2">
          <div className="row gx-5">
            <div className="col">
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="text-white">
                  {team1.teamName} vs {team2.teamName}
                </h2>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="text-white">
                  {team1.totalPoints} - {team2.totalPoints}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </header>
    );

    const matchesToDisplay = matches.filter((x) => x.matchTypeKey == selectedMatchTypeKey);

    return (
      <Layout years={years} selectedYear={selectedYear} header={header}>
        <>
          <ul className="nav nav-pills nav-fill justify-content-between mt-3">
            <li className="nav-item">
              <button
                className={`nav-link ${selectedMatchTypeKey == matchTypeKeys.Fourballs ? 'active' : null}`}
                onClick={() => setSelectedMatchTypeKey(matchTypeKeys.Fourballs)}
              >
                Fourballs
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${selectedMatchTypeKey == matchTypeKeys.Foursomes ? 'active' : null}`}
                onClick={() => setSelectedMatchTypeKey(matchTypeKeys.Foursomes)}
              >
                Foursomes
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${selectedMatchTypeKey == matchTypeKeys.Singles ? 'active' : null}`}
                onClick={() => setSelectedMatchTypeKey(matchTypeKeys.Singles)}
              >
                Singles
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${selectedMatchTypeKey == 'TeamRosters' ? 'active' : null}`}
                onClick={() => setSelectedMatchTypeKey('TeamRosters')}
              >
                Team Rosters
              </button>
            </li>
          </ul>
          <div className="tab-content border-start border-end border-bottom border-top mt-2 p-2 mb-5">
            {selectedMatchTypeKey == 'TeamRosters' && (
              <>
                <h1 className="display-3 text-center">Teams</h1>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="card mb-3">
                      <div className="card-body">
                        <h5 className="card-title">{team1.teamName}</h5>
                        <ul className="list-unstyled">
                          {team1.teamPlayers.map((tp, index) => (
                            <li key={`t1_p${index}`}>
                              {tp.playerName.nameFirstLast} {tp.teamRole && <small>{tp.teamRole}</small>} ({tp.handicap})
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="card mb-3">
                      <div className="card-body">
                        <h5 className="card-title">{team2.teamName}</h5>
                        <ul className="list-unstyled">
                          {team2.teamPlayers.map((tp, index) => (
                            <li key={`t2_p${index}`}>
                              {tp.playerName.nameFirstLast} {tp.teamRole && <small>{tp.teamRole}</small>} ({tp.handicap})
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedMatchTypeKey != 'TeamRosters' && (
              <>
                <div className="text-center mb-5">
                  <h1 className="fw-bolder">Matches</h1>
                </div>
                {matchesToDisplay && matchesToDisplay.length > 0 ? (
                  <>
                    {matchesToDisplay.map((x) => (
                      <MatchInstance key={x.id} match={x} holes={holes} />
                    ))}
                    {matchesToDisplay.every((x) => x.isConverted == false) && (
                      <>
                        {selectedMatchTypeKey == matchTypeKeys.Fourballs && (
                          <>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <h3>Gross Skins</h3>
                                {renderSkinsResultTable(fourballGrossSkins)}
                              </div>
                              <div className="col-12 col-md-6">
                                <h3>Net Skins</h3>
                                {renderSkinsResultTable(fourballNetSkins)}
                              </div>
                            </div>
                            <div className="row">
                              <h3>Net Leaderboard</h3>
                              <div className="col">{renderNetScoreTable(matchesToDisplay)}</div>
                            </div>
                          </>
                        )}
                        {selectedMatchTypeKey == matchTypeKeys.Foursomes && (
                          <>
                            <div className="row">
                              <div className="col">
                                <h3>Net Skins</h3>
                                {renderSkinsResultTable(foursomesNetSkins)}
                              </div>
                            </div>
                            <div className="row">
                              <h3>Net Leaderboard</h3>
                              <div className="col">{renderNetScoreTable(matchesToDisplay)}</div>
                            </div>
                          </>
                        )}
                        {selectedMatchTypeKey == matchTypeKeys.Singles && (
                          <>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <h3>Gross Skins</h3>
                                {renderSkinsResultTable(singlesGrossSkins)}
                              </div>
                              <div className="col-12 col-md-6">
                                <h3>Net Skins</h3>
                                {renderSkinsResultTable(singlesNetSkins)}
                              </div>
                            </div>
                            <div className="row">
                              <h3>Net Leaderboard</h3>
                              <div className="col">{renderNetScoreTable(matchesToDisplay)}</div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <h2>Matches not yet scheduled. Check back later</h2>
                )}
              </>
            )}
          </div>
        </>
      </Layout>
    );
  }
};
